.recordingContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}

.playButton {
  background-color: #f5f5f5;
}

.playButton:hover {
  background-color: #e0e0e0;
}

.duration {
  color: #666;
}

.textValue {
  color: #666;
}

.nameValue {
  color: #666;
  cursor: pointer;
  text-decoration: underline;
}

.nameValue:hover {
  color: #333;
}
