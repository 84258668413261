.wrap {
  border-radius: 5;
  margin: 1rem;
}

.wrap span {
  color: #333;
  font-size: 0.9rem;
}

.error {
  background-color: 'red';
}

.dialogTitle {
  position: relative;
  padding-right: 48px;
}

.closeButton {
  position: absolute !important;
  right: 8px;
  top: 8px;
}

.dialogContent {
  display: flex;
  flex-direction: column;
  gap: 16px;
  min-height: 400px;
  padding: 24px;
}

.audioSection {
  flex-shrink: 0;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: #f0f0f0;
}

.conversationSection {
  flex-grow: 1;
  overflow-y: auto;
  min-height: 300px;
  padding: 24px;
}

.messageContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.message {
  display: flex;
  flex-direction: column;
  gap: 4px;
  max-width: 80%;
}

.userMessage {
  composes: message;
  align-self: flex-end;
}

.botMessage {
  composes: message;
  align-self: flex-start;
}

.roleLabel {
  font-size: 12px;
  color: #666;
  margin-bottom: 4px;
}

.userRoleLabel {
  composes: roleLabel;
  text-align: right;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.botRoleLabel {
  composes: roleLabel;
  text-align: left;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.timeLabel {
  font-size: 12px;
  color: #999999;
  margin-left: 8px;
  display: inline;
}

.messageContent {
  padding: 12px 16px;
  border-radius: 12px;
  word-break: break-word;
}

.userMessageContent {
  composes: messageContent;
  background-color: #d6e0f1;
  color: #38527e;
  border-radius: 12px 12px 0 12px;
}

.botMessageContent {
  composes: messageContent;
  background-color: #e6e6e6;
  color: #666666;
  border-radius: 12px 12px 12px 0;
}
