.audioPlayer {
  width: 50%;
  margin: 8px 0;
}

.errorText {
  color: #d32f2f;
  margin-bottom: 8px;
  font-size: 0.75rem;
}

.cardContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}